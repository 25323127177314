import { useState } from 'react'
import { graphql } from 'gatsby'
import { string } from 'yup'
import { useTranslation } from 'react-i18next'
import { Yes } from '@dfds-ui/icons/system'
import { css, useTheme } from '@emotion/react'
import {
  Button,
  Headline,
  ListItem,
  ListText,
  ListIcon,
  Spinner,
} from '@dfds-ui/react-components'
import { track } from '@dfds-frontend/tracking'

import { FlexCard } from '../Card'
import {
  FormContextProvider,
  FormField,
  GdprFormCheckbox,
} from './../../components/form'
import { subscribe } from './NewsLetterHelpers'
import { AspectImage } from '../Image'
import { useLocaleContext } from '../LocaleContext'
import {
  useBusinessUnitContext,
  BusinessUnitTypes,
} from '../BusinessUnitContext'

const NewsletterSubscribeBlock = (props) => {
  const { language } = useLocaleContext()
  const { t } = useTranslation()

  const {
    reverse,
    image,
    sys,
    title,
    successTitle,
    textContentCollection,
    postVerificationPageUrl,
    cdmSourceId,
  } = props

  const themeContext = useTheme()

  const [submitting, setSubmitting] = useState(false)
  const [hasSubscribed, setHasSubscribed] = useState(false)
  const [requestFailed, setRequestFailed] = useState(false)
  const currentBusinessUnit = useBusinessUnitContext()

  const getBusinessArea = () => {
    switch (currentBusinessUnit) {
      case BusinessUnitTypes.PASSENGER:
        return 'Passenger'
      case BusinessUnitTypes.LOGISTICS:
        return 'Logistics'
      case BusinessUnitTypes.FREIGHT:
        return 'Freight'
      case BusinessUnitTypes.GROUP:
        return 'Group'
      case BusinessUnitTypes.OTHER:
      default:
        return 'Other'
    }
  }

  const businessArea = getBusinessArea()

  const submit = (values) => {
    const webserviceUrl = `${process.env.GATSBY_BFF_URL}newsletter/subscribe`
    const subscribeProps = {
      webserviceUrl,
      postVerificationPageUrl,
      language,
      businessArea,
      cdmSourceId,
      email: values.email,
      setHasSubscribed,
      setSubmitting,
      setRequestFailed,
    }
    setSubmitting(true)
    subscribe(subscribeProps)
    track({ event: 'newsletterSignUp' })
  }

  const emailValidation = string().email(t('THE-FIELD-IS-REQUIRED'))

  return (
    <FlexCard
      mediaWidth={{ md: 8 }}
      reverse={reverse}
      media={image ? <AspectImage {...image} /> : null}
      id={sys.id}
    >
      <Headline as={'h2'}>
        {hasSubscribed && successTitle ? successTitle : title}
      </Headline>
      {!hasSubscribed ? (
        <>
          {!requestFailed ? (
            <FormContextProvider
              onSubmit={submit}
              requiredFieldError={t('FORM-ERROR-MESSAGE-REQUIRED')}
            >
              <>
                {textContentCollection?.items && (
                  <ul
                    css={css`
                      margin: 16px 0 32px;
                      padding: 0;
                    `}
                  >
                    {textContentCollection.items.map(({ sys, textContent }) => (
                      <ListItem
                        as={'li'}
                        key={sys.id}
                        css={css`
                          margin: 0 0 16px 0;
                          padding: 0;
                          min-height: 1rem;
                        `}
                      >
                        <ListIcon
                          icon={Yes}
                          color={themeContext['icon-fill']}
                        />
                        <ListText styledAs={'label'}>{textContent}</ListText>
                      </ListItem>
                    ))}
                  </ul>
                )}
                <FormField
                  name="email"
                  label={t('FORM-EMAIL-ADDRESS')}
                  type="text"
                  validation={emailValidation}
                  hintText={t('FORM-EMAIL-ADDRESS')}
                  required
                />
                <GdprFormCheckbox
                  label={t('NEWSLETTER-SUBSCRIBE-CHECKBOX-LONG-LABEL')}
                />
                <Button
                  type="submit"
                  css={css`
                    width: 100%;
                  `}
                >
                  {!submitting ? (
                    t('NEWSLETTER-SUBSCRIBE-BUTTON-TEXT')
                  ) : (
                    <Spinner />
                  )}
                </Button>
              </>
            </FormContextProvider>
          ) : (
            <p>{t('NEWSLETTER-UNSUBSCRIBE-ERROR-MESSAGE')}</p>
          )}
        </>
      ) : (
        <p>{t('NEWSLETTER-SUBSCRIBE-THANK-YOU')}</p>
      )}
    </FlexCard>
  )
}

export const newsletterSubscribeBlockFragment = graphql`
  fragment NewsletterSubscribeBlock on contentful_NewsletterSubscribeBlock {
    __typename
    sys {
      id
    }
    title
    successTitle
    textContentCollection(limit: 4) {
      items {
        sys {
          id
        }
        textContent
      }
    }
    image {
      ...Asset
    }
    cdmSourceId
    postVerificationPageUrl
  }
`

export default NewsletterSubscribeBlock
